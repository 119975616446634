import { Form, InputNumber, Tabs, DatePicker } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Loading from "../../loading/Loading";
import { useEmployeeContext } from "../Employee.context";
import { useEmployeeContractKMs } from "../../../shared/hooks/useEmployeeContractKMs";
import { useEmployeeContractACs } from "../../../shared/hooks/useEmployeeContractACs";
import FieldWithHistory from "./FieldWithHistory";
import { IField } from "./FieldWithHistory/FiledWithHistory.interface";

import BenefitForm from './BenefitForm';
import BenefitACForm from './BenefitACForm';
import { useState } from "react";

const EmployeeBenefits = () => {
  const defaultKey = 'contractKMs';
  const [activeKey, setActiveKey] = useState(defaultKey);

  const employee = useEmployeeContext();
  const {
    data: contractKMsData,
    isLoading: isLoadingKMs,
    createData: createContractKMs,
    deleteData: deleteContractKMs,
  } = useEmployeeContractKMs(employee.id);
  const { t } = useTranslation();

  const {
    data: contractACsData,
    isLoading: isLoadingACs,
    createData: createContractACs,
    deleteData: deleteContractACs,
  } = useEmployeeContractACs(employee.id);

  if (isLoadingKMs || isLoadingACs) return <Loading />;

  const onCreateContractKMs = (values: IField) => {
    createContractKMs({
      ...values,
      value: parseFloat(values.value),
      validSince: values.date.format("YYYY-MM-01"),
    });
  };

  const onDeleteContractKMs = (values: IField) => {
    deleteContractKMs({
      ...values,
      value: parseFloat(values.value),
      validSince: values.date.format("YYYY-MM-DD"),
    });
  };

  const onCreateContractAC = (values: IField) => {
    createContractACs({
      ...values,
      value: parseFloat(values.value),
      validSince: values.date.format("YYYY-MM-01"),
      valuePerUnit: 0,
    });
  };

  const onDeleteContractAC = (values: IField) => {
    deleteContractACs({
      ...values,
      value: parseFloat(values.value),
      validSince: values.date.format("YYYY-MM-DD"),
      valuePerUnit: 0
    });
  };

  const setKey = (key:string) => {
    setActiveKey(key);
  }

  const contractKMs = {
    label: t("employee.kms"),
    key: defaultKey,
    children: (
      <FieldWithHistory
        data={contractKMsData.map(({ value, isCurrent, validSince }) => ({ 
          value: `${value} €`,
          isCurrent,
          date: dayjs(validSince),
        }))}
        description={t("employee.kms.description")}
        onCreate={onCreateContractKMs}
        onDelete={onDeleteContractKMs}
        modalDescription={t("employee.newCompensation")}
        showActiveValue={true}
        valueFormComponent={
          <BenefitForm />
        }
      />
    ),
  };

  const contractACs = {
    label: t("employee.ac"),
    key: "contractACs",
    children: (
      <FieldWithHistory
        data={contractACsData.map(({ value, isCurrent, validSince }) => ({ 
          value: `${value} €`,
          isCurrent,
          date: dayjs(validSince),
        }))}
        description={t("employee.ac.description")}
        onCreate={onCreateContractAC}
        onDelete={onDeleteContractAC}
        modalDescription={t("employee.newCompensation")}
        showActiveValue={true}
        valueFormComponent={
          <BenefitACForm />
        }
      />
    ),
  }

  return <Tabs activeKey={activeKey} onTabClick={(key) => setKey(key)} tabPosition="left" items={[contractKMs, contractACs]} />;
};

export default EmployeeBenefits;
