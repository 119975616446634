import {useState, useEffect, useCallback, useMemo} from 'react'

import {
    SaveOutlined,
    CloseOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";

import {
    DatePicker,
    Space, 
    Row, 
    Col, 
    Typography, 
    Button, 
    Input, 
    Form,
    Switch,
    Tooltip,
    InputNumber,
    Radio,
    notification
} from 'antd';

import { useTranslation } from 'react-i18next';
import { formDefaults } from '../../shared/constants/work-items';
import useBenefits from '../../shared/hooks/useBenefits';

const { RangePicker } = DatePicker;

const { Title } = Typography;

const EditWorkItems = ({
    selectedWorkItem = null, 
    onCancelHandler = null,
    onSuccessHandler = null,
    createNew = false,
}) => {
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const [formState, setFormState] = useState(formDefaults);
    const { formBenefit, computeFormWorkItem, convertFormToWorkItemAPI, createBenefit, updateBenefit } = useBenefits();
    const [ changed, setChanged ] = useState(false);
    const [shouldRefetch, setShouldRefetch] = useState(false);

    const shouldSetForm = useMemo(()=> {
        return !changed && formBenefit !== null && formBenefit.benefitId !== form.getFieldValue('benefitId')
    },[changed, formBenefit, formBenefit, form.getFieldValue]);

    const shouldConvertBenefit = useMemo(()=> {
        return selectedWorkItem !== null && selectedWorkItem.benefitId !== formBenefit?.benefitId
    },[selectedWorkItem, formBenefit])

    const shouldCreateNew = useMemo(()=>{
        return createNew && formState.benefitId === null || formState.benefitId === undefined
    },[createNew, formState])

    useEffect(()=> {
        if(shouldConvertBenefit){
            computeFormWorkItem(selectedWorkItem, formState);
            setFormState(prev => ({
                ...prev,
                ...formDefaults
            }));
            setChanged(false);
        } 
        if(shouldSetForm){
            form.setFieldsValue(formBenefit);
            setFormState(prev => ({
                ...prev,
                disabled: {
                    state: !formBenefit.state,
                    vat: !formBenefit.vat,
                }
            }));
            setChanged(true);
        }
    },[selectedWorkItem, formBenefit, computeFormWorkItem, form.setFieldsValue, changed, setChanged, setFormState, formDefaults ]);

    const onStateChange = (checked) => {
        const inverseChecked = !checked;
        if(inverseChecked){
            form.setFieldsValue({'stateRange': null})
        }
        setFormState({...formState,
            disabled: {...formState.disabled, state: inverseChecked}
        });
    }

    const onVATChange = (checked) => {
        const inverseChecked = !checked;

        if(inverseChecked){
            form.setFieldsValue({'vatValue': null})
        }
        setFormState({...formState,
            disabled: {...formState.disabled, vat: inverseChecked}
        });
    }

    const callSuccessHandler = (refetch) => {
        if(onSuccessHandler !== null) {
            onSuccessHandler(refetch);
        }
    }

    const onFinish = (values) => {
        const data = convertFormToWorkItemAPI(values);
        if(shouldCreateNew) {
            createBenefit(
                data,
                (response) => {
                    
                    notification.success({
                        message: t('workItemCreatedSuccess'),
                        placement: "bottomRight",
                    });
                    setShouldRefetch(true);
                    callSuccessHandler(true);
                    setFormState(prev => ({
                        ...prev,
                        benefitId: response.data.benefitId
                    }));
                    setChanged(false);
                    form.setFieldValue('benefitId', response.data.benefitId);
                },
                (error) => {
                    notification.error({
                        message: t('workItemCreatedFail'),
                        description: error?.message ?? '',
                        placement: "bottomRight",
                    });
                    setShouldRefetch(false);
                }
            )
        } else {
            updateBenefit(data,
                (_) => {
                    notification.success({
                        message: t('workItemUpdatedSuccess'),
                        placement: "bottomRight",
                    });
                    setShouldRefetch(true);  
                    callSuccessHandler(true);         
                },
                (error) => {
                    notification.error({
                        message: t('workItemUpdatedFail'),
                        description: error?.message ?? '',
                        placement: "bottomRight",
                    });
                    setShouldRefetch(false);
                }            
            )
        }
        setChanged(false);
    }

    const onCancel = () => {
        form.resetFields();
        if (onCancelHandler !== null) {
            onCancelHandler(shouldRefetch);
        }
    }

    const evalSave = () => {
        let canSave = true;
        if (
            form.getFieldValue('maxValue') !== null &&
            form.getFieldValue('defaultValue') !== null &&
            form.getFieldValue('maxValue') < form.getFieldValue('defaultValue')
        ){
            canSave = false;
            notification.error({
                message: t('workItemCreatedUpdateFail'),
                description: t('workItemCreatedFailValueSwappedMessage'),
                placement: "bottomRight",
            });
        }
        return canSave;
    }

    const onSave = useCallback(() => {
        if(evalSave()){
            form.validateFields().then(() => {
                form.submit();
            }).catch(() => {
                notification.error({
                    message: t('workItemCreatedFail'),
                    description: t('workItemCreatedFailMessage'),
                    placement: "bottomRight",
                });
            });    
        }
    },[form, evalSave]);

    return (
        <Row justify={'center'}>
            <Col sm={24} md={24}>
                <Form
                    form={form}
                    layout="vertical"
                    name="work-items-form"
                    onFinish={onFinish}
                    initialValues={formState.defaults}
                    disabled={formBenefit === null && createNew === false}
                >
                    <Row className='paddingTop2Rem'>
                        <Col sm={24} md={24} className='alignLeft'>
                            <Title level={5}>{t('workItems')}</Title>
                            <Form.Item 
                                    name="benefitId"
                                    style={{display: 'none'}}
                                >
                                    <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row> 

                    <Row justify={'center'}>
                        <Col sm={24} md={24}>
                            <Form.Item
                                label={t("workItemsDescription")}
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                            >
                                <Input 
                                    placeholder={t('benefitDescriptionPlaceHolder')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className='paddingTop2Rem'>
                        <Col sm={24} md={24} className='alignLeft'>
                            <Space direction='horizontal' size={'middle'}>
                                <Title level={5}>
                                    <span className='paddingRightHalfRem'>{t('benefit')}</span>
                                    <Tooltip placement="right" title={t("workItemsStateTooltip")}>
                                        <InfoCircleOutlined />
                                    </Tooltip>
                                </Title>
                            </Space>
                        </Col>                 
                    </Row>

                    <Row>
                        <Col sm={24} md={4} className='alignLeft'>
                            <Form.Item
                                label={t("workItemsState")}
                                name="state"
                            >
                                <Switch onChange={onStateChange} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={20} className='alignLeft'>
                            <Form.Item
                                label={t("workItemsStateDates")}
                                name="stateRange"
                            >
                                <RangePicker picker="day" size="large" className="fullWidth" disabled={formState.disabled.state} />
                            </Form.Item>
                        </Col> 
                    </Row>

                    <Row className='paddingTop2Rem'>
                        <Col sm={24} md={24} className='alignLeft'>
                            <Title level={5}>
                                <span>{t('workItemsProposals')}</span>
                            </Title>        
                        </Col>                 
                    </Row>

                    <Row>
                        <Col sm={24} md={4}>
                            <Form.Item
                                label={t("workItemsProposalsBasePackage")}
                                name="proposals"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>        
                        </Col>

                        <Col sm={24} md={6}>
                            <Form.Item
                                label={t("workItemsProposalsValue")}
                                name="maxValue"
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                            >
                                <InputNumber 
                                    addonAfter={t('euroSymbol')}
                                />
                            </Form.Item>
                        </Col>

                        <Col sm={24} md={14} className='alignLeft'>
                            <Form.Item
                                    label={' '}
                                    name="frequency"
                                    valuePropName="value"
                                    className='marginLeft1Rem'
                                >
                                <Radio.Group>
                                    <Radio value="month">{t("workItemsPaymentMonthLabel")}</Radio>
                                    <Radio value="single">{t("workItemsPaymentSingleLabel")}</Radio>
                                </Radio.Group>
                            
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={24} md={4}>
                            <Form.Item
                                label={t("workItemsGroupStdValue")}
                                name="defaultValue"
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                            >
                                <InputNumber className='fullWidth' addonAfter={t('euroSymbol')}/>
                            </Form.Item>  
                        </Col>
                        <Col sm={24} md={4}>
                            <Form.Item
                                label={t("workItemsGroupMonths")}
                                name="months"
                                className='marginLeft1Rem'
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                            >
                                <InputNumber max={12} min={0} className='fullWidth'/>
                            </Form.Item>  
                        </Col>
                        <Col sm={24} md={8} className='alignLeft'>
                            <Form.Item
                                label={t("workItemsGroupIRS")}
                                name="irs"
                                className='marginLeft1Rem'
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value="yes">{t("workItemsProposalsYes")}</Radio>
                                    <Radio value="no">{t("workItemsProposalsNo")}</Radio>
                                </Radio.Group>  
                            </Form.Item>  
                        </Col>
                        <Col sm={24} md={16}></Col>
                    </Row>

                    <Row className='paddingTop2Rem'>
                        <Col sm={24} md={24} className='alignLeft'>
                            <Title level={5}>
                                <span>{t('workItemsOtherCost')}</span>
                            </Title>        
                        </Col>                       
                    </Row>
                    <Row>
                        <Col sm={24} md={8} className='alignLeft'>
                            <Form.Item
                                    label={t("workItemsOtherCostTribAuto")}
                                    name="tribAuto"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("workItemsRequiredGenericMessage"),
                                        },
                                    ]}
                                >
                                    <InputNumber max={100} min={0} addonAfter={t('percentSymbol')} />
                            </Form.Item>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col sm={24} md={4} className='alignLeft'>
                            <Form.Item
                                label={t("workItemsOtherCostTaxDeduct")}
                                name="vatDeductible"
                                rules={[
                                    {
                                        required: true,
                                        message: t("workItemsRequiredGenericMessage"),
                                    },
                                ]}
                                className='marginRight1Rem'

                            >
                                <Switch onChange={onVATChange} />
                            </Form.Item>  
                        </Col>
                        <Col sm={24} md={4} className='alignLeft'>
                            <Form.Item
                                    label={' '}
                                    name="vatValue"
                                >
                                    <InputNumber max={100} min={0} addonAfter={t('percentSymbol')} disabled={formState.disabled.vat} />
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={16}></Col>
                    </Row>

                    <Row>
                        <Col sm={24} md={24} className='alignRight'>
                            <Space direction='horizontal' size={'middle'}>
                                <Button onClick={() => onCancel()} icon={<CloseOutlined />}>{t('workItemsCancelBtn')}</Button>
                                <Button type='primary' onClick={() => onSave()} icon={<SaveOutlined />}>{t('workItemsSaveBtn')}</Button>
                            </Space>
                        </Col>
                    </Row>

                </Form>
            </Col>
        </Row>
    )
}



export default EditWorkItems;