import useHandleApiData from "./useHandleApiData";
import { Employees } from "../clients/Employees";

export const useEmployeeContractACs = (employeeId: string) => {
  return useHandleApiData(
    employeeId,
    Employees.contractACs,
    Employees.createContractACs,
    Employees.deleteContractACs
  );
};
