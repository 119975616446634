import { Space, Typography } from "antd";
import ActiveValue from "./ActiveValue";
import { IFieldWithHistory } from "./FiledWithHistory.interface";
import IHistoryComponent from "./History.component";

const FieldWithHistory = ({
  data = [],
  description,
  modalDescription,
  valueFormComponent,
  onCreate,
  onDelete,
  showActiveValue = false,
  onCustomAddItem,
  headerComponent,
}: IFieldWithHistory) => {
  const activeValue = data?.find((field) => field.isCurrent);
  return (
    <>
      <Space direction="vertical" size="large">
        {description && (
          <Typography.Title level={3} style={{ margin: 0 }}>
            {description}
          </Typography.Title>
        )}      
        <ActiveValue
          field={activeValue}
          modalDescription={modalDescription}
          onCreate={onCreate}
          valueFormComponent={valueFormComponent}
          showActiveValue={showActiveValue}
          onCustomAddItem={onCustomAddItem}
          headerComponent={headerComponent}
        />
      </Space>
      <IHistoryComponent history={data} onDelete={onDelete} />
    </>
  );
};

export default FieldWithHistory;
